// decorating for subsections such as institutes, faculty, library etc.
#head-wrapper {
  #head #header .parent {
    min-height: 90px;
    display: flex;
    align-items: center;
    .inner {
      display: flex;
      flex-direction: column;
      row-gap: var(--space-xs);
      .title {
        background-image: var(--logo-inv);
        background-repeat: no-repeat;
        width: 236px;
        height: 58px;
        text-indent: -100000px;
        background-size: contain;
      }
    }
  }
  .main-actor-and-affiliations {
    color: var(--color-white);
    font-size: 3.4rem;
    a { 
      text-decoration: none;
      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }
}
body.en #head-wrapper #head #header .parent .inner .title {
  background-image: var(--logo-eng-inv);
}

.bibliotek {
  #head-wrapper {
    #head #header {
      column-gap: 20px;
      justify-content: space-between;
      #header-tools {
        margin-left: unset;
      }
    }
    @media (@screen-below-medium) {
      padding-bottom: 50px;
      .parent .inner .title {
        width: 180px;
        height: 49px;
      }
      .main-actor-and-affiliations {
        position: absolute;
        top: 70px;
        left: 60px;
      }
    }
  }
}

body.himoldex {
  #head-wrapper {
    border-bottom: 1px solid var(--color-darkblue);
    background: var(--color-white);
    #head #header {
      .go-back {
        color: var(--color-darkblue);
        padding-left: 10px;
        &::before {
          content: "";
          background: var(--img-caret) no-repeat center;
          background-size: contain;
          width: 0.9em;
          height: 1em;
          position: absolute;
          margin-left: -15px;
          top: 4px;
          transform: rotate(180deg);
        }
      }
      .himoldex-logo img {
        min-width: 170px;
        padding: var(--space-s) 0;
      }
    }
  }
  .sidebar-menu-wrapper .sidebar-menu, #head-admin-menu-wrapper, .sidebar-menu-wrapper-visible #head-admin-menu-wrapper {
    background: var(--color-white);
    color: var(--color-black);
  }
}